
import { Vue, Component, Ref } from "vue-property-decorator";
import { mapMutations, mapGetters } from "vuex";
import { ResponseDataStringArray as rdsa } from "@/interfaces";
import * as types from "@/store/types";
import { component } from "vue/types/umd";
@Component({
    components: {
        FilterFormPanelBase: () =>
            import("@/components/layout/FilterFormPanelBase.vue"),
        CardWidget: () => import("@/components/layout/CardWidget.vue"),
        CalendarRangeInput: () =>
            import("@/components/layout/CalendarRangeInput.vue")
    },
    methods: {
        ...mapMutations({
            setProgressBar: types.MUTATE_APP_PROGRESSBAR,
            setInfoMessage: types.MUTATE_APP_INFO_MESSAGE
        })
    },
    computed: {
        ...mapGetters({
            userIdentifier: types.USER_IDENTIFIER
        })
    }
})
export default class FormSaving extends Vue {
    setProgressBar!: (state: boolean) => void;
    setInfoMessage!: (state: { shown: boolean; text: string | null }) => void;
    showError(error: string) {
        this.setInfoMessage({ shown: true, text: error });
    }
    role = Vue.prototype.$user.get().role;
    userIdentifier?: number;
    companies: string[] = [];
    company = "";
    clients: string[] = [];
    client = "";
    installations: string[] = [];
    install = "";
    params: any[] = [];
    periods = ["Diario", "Semanal", "Mensual", "Personalizado"];
    period = "Diario";
    rules = {
        required: value => !!value || "Requerido",
        counter: value => value.length <= 255 || "Maximo 255 caracteres",
        values: value => !!Number(value) || "No es un número",
        length: value => value.length <= 7 || "Maximo 7 dígitos"
    };
    costeMean = "";
    consumoMean = "";
    aux0 = "";
    aux1 = "";
    aux2 = "";
    aux3 = "";
    title = "";
    description = "";
    dates = [new Date().toISOString().split("T")[0]];
    datesComp = [new Date().toISOString().split("T")[0]];
    consumo = "";
    coste = "";
    costeMax = "";
    costeRef = "";
    services: string[] = [];
    service = "";
    forma = "media";
    lines: any[] = [];
    Pmodo = "Contrato";
    modos = ["Contrato", "Precio de Mercado"];
    maxP = 0;
    maxC = 0;
    minC = 0;
    minP = 0;
    defaultD = [
        {
            Horas: [0, 288],
            Weekend: true,
            inter: 0,
            min: 0,
            id: Math.random()
                .toString(36)
                .slice(3, 8)
        }
    ];
    daily = Object.assign([], this.defaultD);
    defaultW = [
        {
            Dia: "Lunes",
            Horas: [0, 288],
            Igual: true,
            WD: 0,
            inter: 0,
            min: 0,
            id: Math.random()
                .toString(36)
                .slice(3, 8)
        },
        {
            Dia: "Martes",
            Horas: [0, 288],
            Igual: true,
            WD: 1,
            inter: 0,
            min: 0,
            id: Math.random()
                .toString(36)
                .slice(3, 8)
        },
        {
            Dia: "Miercoles",
            Horas: [0, 288],
            Igual: true,
            WD: 2,
            inter: 0,
            min: 0,
            id: Math.random()
                .toString(36)
                .slice(3, 8)
        },
        {
            Dia: "Jueves",
            Horas: [0, 288],
            Igual: true,
            WD: 3,
            inter: 0,
            min: 0,
            id: Math.random()
                .toString(36)
                .slice(3, 8)
        },
        {
            Dia: "Viernes",
            Horas: [0, 288],
            Igual: true,
            WD: 4,
            inter: 0,
            min: 0,
            id: Math.random()
                .toString(36)
                .slice(3, 8)
        },
        {
            Dia: "Sabado",
            Horas: [0, 288],
            Igual: true,
            WD: 5,
            inter: 0,
            min: 0,
            id: Math.random()
                .toString(36)
                .slice(3, 8)
        },
        {
            Dia: "Domingo",
            Horas: [0, 288],
            Igual: true,
            WD: 6,
            inter: 0,
            min: 0,
            id: Math.random()
                .toString(36)
                .slice(3, 8)
        }
    ];
    weekly = Object.assign([], this.defaultW);
    loading = 0;
    overlay = false;

    mounted() {
        this.fetchUser();
    }

    async fetchUser() {
        if (this.role == "client") {
            this.setProgressBar(true);
            try {
                const userClientResponse = await this.$api.SQLuserClient<rdsa>(
                    this.userIdentifier
                );
                if (userClientResponse.ok) {
                    this.company = userClientResponse.data[0];
                    this.client = userClientResponse.data[1];
                    this.fetchInstallation();
                }
            } catch (error) {
                if (error instanceof Error) {
                    this.showError(error.message);
                }
            } finally {
                this.setProgressBar(false);
            }
        } else if (this.role == "admin") {
            this.fetchCompany();
        } else {
            console.log("Role undefined");
        }
    }

    async fetchCompany() {
        this.setProgressBar(true);
        try {
            const companyResponse = await this.$api.SQLcompany<rdsa>();
            if (companyResponse.ok) {
                this.companies = companyResponse.data;
                this.company = this.companies[0];
                this.fetchClient();
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        } finally {
            this.setProgressBar(false);
        }
    }

    async fetchClient() {
        this.setProgressBar(true);
        this.reset0();
        try {
            const clientResponse = await this.$api.SQLclient<rdsa>(
                this.company.split(" - ")[0]
            );
            if (clientResponse.ok) {
                this.clients = clientResponse.data;
                this.client = this.clients[0];
                this.fetchInstallation();
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        } finally {
            this.setProgressBar(false);
        }
    }

    async fetchInstallation() {
        this.setProgressBar(true);
        this.reset1();
        try {
            const installResponse = await this.$api.SQLelementoAhorroService<
                rdsa
            >(this.client.split(" - ")[0]);
            this.services = [];
            if (installResponse.ok) {
                this.params = installResponse.data;
                this.params.forEach(i => {
                    if (i["servicio"] != 0) {
                        this.services.push(i["servicio"]);
                    }
                });
                const aux: string[] = [];
                this.services.forEach(function(item) {
                    if (aux.indexOf(item) < 0) {
                        aux.push(item);
                    }
                });
                this.services = aux;
                this.services.sort();
                this.service = this.services[0];
                this.getService();
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        } finally {
            this.setProgressBar(false);
        }
    }

    getService() {
        this.installations = [];
        this.params.forEach(i => {
            if (i["servicio"] == this.service || i["servicio"] == 0) {
                if (i["servicio"] == 0) {
                    for (const j of this.params) {
                        if (
                            j["Telement"].includes(
                                i["Telement"].split(" - ")[0]
                            ) &&
                            j["servicio"] == this.service
                        ) {
                            this.installations.push(i["Telement"]);
                            break;
                        }
                    }
                } else {
                    this.installations.push(i["Telement"]);
                }
            }
        });
        this.install = this.installations[0];
        if (Number(this.service.split(" - ")[0]) <= 2) {
            this.Pmodo = "Contrato";
            this.modos = ["Contrato", "Precio de Mercado"];
        } else {
            this.Pmodo = "Contrato";
            this.modos = ["Contrato"];
        }
    }

    async getMean() {
        this.overlay = true;
        this.loading = 6;
        if (this.forma == "media") {
            try {
                const aux: any[] = [];
                const aux1 = this.params.find(
                    i => i["Telement"] == this.install
                );
                const aux2 = aux1["Telement"].split(" - ")[0].split("I")[1];
                if (aux1["servicio"] == 0) {
                    this.params.forEach(i => {
                        if (
                            i["Telement"].split(" - ")[0].split("I")[1] ==
                                aux2 &&
                            i["servicio"] == this.service
                        ) {
                            aux.push(i);
                        }
                    });
                } else {
                    aux.push(aux1);
                }
                const data = {
                    date: this.datesComp,
                    serv: this.service,
                    per: this.period,
                    ele: aux
                };
                if (this.period == "Diario") {
                    data["data"] = this.daily;
                } else if (this.period == "Semanal") {
                    data["data"] = this.weekly;
                }
                const servicesResponse = await this.$api.getMeanServices<rdsa>(
                    this.install.split(" - ")[0],
                    this.period,
                    this.Pmodo,
                    data
                );
                if (servicesResponse.ok) {
                    this.costeMean = servicesResponse.data["coste"];
                    this.minC = servicesResponse.data["minC"];
                    this.consumoMean = servicesResponse.data["consumo"];
                    this.minP = servicesResponse.data["minP"];
                    this.maxC = servicesResponse.data["maxC"];
                    this.maxP = servicesResponse.data["maxP"];
                    this.putMean();
                }
            } catch (error) {
                if (error instanceof Error) {
                    this.showError(error.message);
                }
            } finally {
                this.loading = 0;
                this.overlay = false;
            }
        }
    }

    putMean() {
        if (this.forma == "media") {
            this.aux0 = this.consumoMean;
            this.aux1 = this.costeMean;
        }
    }

    lineaProyecto() {
        if (this.period == "Personalizado") {
            this.lines = [];
            if (this.dates.length == 1) {
                this.lines.push({
                    Fecha: this.dates[0],
                    Horas: [0, 288],
                    Igual: true,
                    inter: 0,
                    min: 0,
                    id: Math.random()
                        .toString(36)
                        .slice(3, 8)
                });
            } else {
                let start = new Date(this.dates[0]);
                let end = new Date(this.dates[1]);
                const dias = (Number(end) - Number(start)) / (3600000 * 24);
                if (dias < 0) {
                    start = end;
                    end = new Date(this.dates[0]);
                }
                while (Number(start) <= Number(end)) {
                    this.lines.push({
                        Fecha: start.toISOString().split("T")[0],
                        Horas: [0, 288],
                        Igual: true,
                        inter: 0,
                        min: 0,
                        id: Math.random()
                            .toString(36)
                            .slice(3, 8)
                    });
                    start = new Date(
                        new Date(start).setDate(start.getDate() + 1)
                    );
                }
            }
        }
    }

    selectAll() {
        if (this.period == "Personalizado") {
            for (const item of this.lines) {
                item.Igual = false;
            }
        } else if (this.period == "Semanal") {
            for (const item of this.weekly) {
                item.Igual = false;
            }
        }
    }

    selectEqualLine() {
        let first = true;
        let hora;
        for (const item of this.lines) {
            if (item.Igual == true) {
                if (first) {
                    first = false;
                    hora = item.Horas;
                    continue;
                }
                item.Horas = hora;
            }
        }
    }

    selectEqualWeek() {
        let first = true;
        let hora;
        for (const item of this.weekly) {
            if (item.Igual == true) {
                if (first) {
                    first = false;
                    hora = item.Horas;
                    continue;
                }
                item.Horas = hora;
            }
        }
    }

    formatoHora(value) {
        const aux = new Date(value * 300000);
        let minutes = String(aux.getUTCMinutes());
        let hours = String(aux.getUTCHours());
        if (Number(minutes) < 10) {
            minutes = "0" + minutes;
        }
        if (Number(hours) < 10) {
            hours = "0" + hours;
        }
        return value != 288 ? hours + ":" + minutes : "24:00";
    }

    calcularVal() {
        if (
            this.forma == "media" &&
            this.aux0 &&
            this.aux1 &&
            this.aux2 &&
            this.aux3
        ) {
            this.consumo = this.aux0;
            this.costeRef = this.aux1;
            this.costeMax = String(
                Number(this.aux1) * (1 + Number(this.aux2) / 100)
            );
            this.coste = String(
                Number(this.aux1) * (1 - Number(this.aux3) / 100)
            );
        } else if (
            this.forma == "refer" &&
            this.aux0 &&
            this.aux1 &&
            this.aux2
        ) {
            const per = Number(this.aux2) / 100;
            this.consumo = String(Number(this.aux0) * (1 - per));
            this.costeRef = this.aux1;
            this.costeMax = String(Number(this.aux1) * (1 + per));
            this.coste = String(Number(this.aux1) * (1 - per));
        }
    }

    async saveForm() {
        this.loading = 5;
        this.overlay = true;
        this.calcularVal();
        let save = true;
        try {
            if (this.title.length == 0) {
                this.showError(
                    "El título del proyecto no ha sido diligenciado"
                );
                save = false;
            }
            if (this.description.length == 0) {
                this.showError(
                    "La descripción del proyecto no ha sido diligenciada"
                );
                save = false;
            }
            if (this.dates.length == 0) {
                this.showError(
                    "Las fechas del proyecto no han sido diligenciadas"
                );
                save = false;
            }
            if (this.service.length == 0) {
                this.showError(
                    "El servicio público del proyecto no ha sido diligenciado"
                );
                save = false;
            }
            if (this.consumo.length == 0) {
                this.showError(
                    "El consumo objetivo del proyecto no ha sido diligenciado"
                );
                save = false;
            }
            if (this.coste.length == 0) {
                this.showError(
                    "El coste objetivo del proyecto no ha sido diligenciado"
                );
                save = false;
            }
            if (this.costeMax.length == 0) {
                this.showError(
                    "El coste de referencia del proyecto no ha sido diligenciado"
                );
                save = false;
            }
            if (this.costeRef.length == 0) {
                this.showError(
                    "El coste máximo del proyecto no ha sido diligenciado"
                );
                save = false;
            }
            if (this.period.length == 0) {
                this.showError(
                    "El tipo de periodo del proyecto no ha sido diligenciado"
                );
                save = false;
            }
            if (this.install.length == 0) {
                this.showError(
                    "El elemento del proyecto no ha sido diligenciado"
                );
                save = false;
            }
            if (save) {
                const data = [
                    {
                        title: this.title,
                        desc: this.description,
                        date: this.dates,
                        serv: this.service,
                        cons: this.consumo,
                        cost: this.coste,
                        costRef: this.costeRef,
                        costMax: this.costeMax,
                        per: this.period,
                        ele: this.install
                    }
                ];
                if (this.period == "Diario") {
                    data[0]["data"] = this.daily;
                } else if (this.period == "Semanal") {
                    data[0]["data"] = this.weekly;
                } else if (this.period == "Personalizado") {
                    data[0]["data"] = this.lines;
                }
                const saveProject = await this.$api.saveProyect<rdsa>(
                    this.client.split(" - ")[0],
                    this.Pmodo,
                    data
                );

                if (saveProject.ok) {
                    this.showError(saveProject.data[0]);
                    // eslint-disable-next-line
                    this.$root.$emit("Saving");
                    this.goTo();
                }
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        } finally {
            this.loading = 0;
            this.overlay = false;
        }
    }

    annadirWeek(weekd) {
        let ind = 1;
        const aux = {
            Dia: weekd.Dia,
            Horas: [0, 288],
            Igual: true,
            WD: weekd.WD,
            inter: weekd.inter,
            min: weekd.Horas[1],
            id: Math.random()
                .toString(36)
                .slice(3, 8)
        };
        for (const item of this.weekly) {
            if (item.WD == aux.WD && item.inter == aux.inter) {
                break;
            }
            ind++;
        }
        aux.inter++;
        this.weekly.splice(ind, 0, aux);
    }

    quitarWeek(weekd) {
        let ind = 0;
        for (const item of this.weekly) {
            if (item.WD == weekd.WD && item.inter == weekd.inter) {
                break;
            }
            ind++;
        }
        this.weekly.splice(ind, 1);
    }

    annadirLine(day) {
        let ind = 1;
        const aux = {
            Fecha: day.Fecha,
            Horas: [0, 288],
            Igual: true,
            inter: day.inter,
            min: day.Horas[1],
            id: Math.random()
                .toString(36)
                .slice(3, 8)
        };
        for (const item of this.lines) {
            if (item.Fecha == aux.Fecha && item.inter == aux.inter) {
                break;
            }
            ind++;
        }
        aux.inter++;
        this.lines.splice(ind, 0, aux);
    }

    quitarLine(day) {
        let ind = 0;
        for (const item of this.lines) {
            if (item.WD == day.Fecha && item.inter == day.inter) {
                break;
            }
            ind++;
        }
        this.lines.splice(ind, 1);
    }

    annadirHora(hora) {
        let ind = 1;
        const aux = {
            Horas: [0, 288],
            inter: 0,
            Weekend: true,
            min: hora.Horas[1],
            id: Math.random()
                .toString(36)
                .slice(3, 8)
        };
        for (const item of this.daily) {
            if (item.inter == hora.inter) {
                break;
            }
            ind++;
        }
        aux.inter = ind;
        this.daily.splice(ind, 0, aux);
    }

    quitarHora(hora) {
        let ind = 0;
        for (const item of this.daily) {
            if (item.inter == hora.inter) {
                break;
            }
            ind++;
        }
        this.daily.splice(ind, 1);
    }

    goTo() {
        window.location.replace("/#/saving");
    }

    getMinD(index) {
        try {
            this.daily[index + 1]["min"] = this.daily[index]["Horas"][1];
            if (
                this.daily[index + 1]["Horas"][0] <
                this.daily[index]["Horas"][1]
            ) {
                this.daily[index + 1]["Horas"][0] = this.daily[index][
                    "Horas"
                ][1];
            }
        } catch (error) {
            "do nothing";
        }
    }

    getMinW(index) {
        try {
            if (this.weekly[index]["WD"] == this.weekly[index + 1]["WD"]) {
                this.weekly[index + 1]["min"] = this.weekly[index]["Horas"][1];
                if (
                    this.weekly[index + 1]["Horas"][0] <
                    this.weekly[index]["Horas"][1]
                ) {
                    this.weekly[index + 1]["Horas"][0] = this.weekly[index][
                        "Horas"
                    ][1];
                }
            }
        } catch (error) {
            "do nothing";
        }
    }

    getMinL(index) {
        try {
            if (this.lines[index]["Fecha"] == this.lines[index + 1]["Fecha"]) {
                this.lines[index + 1]["min"] = this.lines[index]["Horas"][1];
                if (
                    this.lines[index + 1]["Horas"][0] <
                    this.lines[index]["Horas"][1]
                ) {
                    this.lines[index + 1]["Horas"][0] = this.lines[index][
                        "Horas"
                    ][1];
                }
            }
        } catch (error) {
            "do nothing";
        }
    }

    ResetPer() {
        this.weekly = Object.assign([], this.defaultW);
        this.daily = Object.assign([], this.defaultD);
    }

    reset0() {
        this.clients = [];
        this.client = "";
        this.installations = [];
        this.install = "";
        this.params = [];
        this.costeMean = "";
        this.consumoMean = "";
        this.aux0 = "";
        this.aux1 = "";
        this.aux2 = "";
        this.aux3 = "";
        this.consumo = "";
        this.coste = "";
        this.services = [];
        this.service = "";
        this.maxP = 0;
        this.maxC = 0;
        this.minC = 0;
        this.minP = 0;
    }

    reset1() {
        this.installations = [];
        this.install = "";
        this.params = [];
        this.costeMean = "";
        this.consumoMean = "";
        this.aux0 = "";
        this.aux1 = "";
        this.aux2 = "";
        this.aux3 = "";
        this.consumo = "";
        this.coste = "";
        this.services = [];
        this.service = "";
        this.maxP = 0;
        this.maxC = 0;
        this.minC = 0;
        this.minP = 0;
    }
}
